import React from "react";
import { motion } from "framer-motion";
import slide1 from "../Assets/home.jpg";
import structure from "../Assets/company.jpeg";
import "../css/Home.css";

function Home() {
  window.scrollTo(0, 0);

  const pageDurationTwo = {
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: "20px",
    },
  };
  const pageTransitionTwo = {
    duration: 1,
  };

  return (
    <div className="home">
      <motion.div
        className="slide-image"
        initial="out"
        animate="in"
        exit="out"
        variants={pageDurationTwo}
        transition={pageTransitionTwo}
      >
        <img src={slide1} />
      </motion.div>
      <div className="home-main">
        <div className="slogan">
          <h2>
            “Quality is never an accident; it is always the result of high
            intention, sincere effort, intelligent direction <br /> and skillful
            execution; it represents the wise choice of many alternatives.”
          </h2>
          <h3> - William A. Foster</h3>
        </div>
        <div className="home-mid-content">
          <div className="mid-content-left">
            <img src={structure} />
          </div>
          <div>
            <div className="mid-content-right">
              <h3>Welcome to Selvan Tex</h3>

              <p>
                Selvan Tex Located at Karur is one of the major Textiles
                manufacturing and exporter especially home furnishing textiles
                in South India. Our primary focus remains timely delivery to our
                clients at all times. A products from Selvan traders comes with
                the promise of quality workmenship. We have direct control over
                the processes.
              </p>
            </div>
            <div className="mid-content-right">
              <h3>Company's Profile</h3>

              <p>
                Selvan Tex is Established in 1989 by R.Kasthuri our Beloved
                Owner. Our Products are 100% Cotton home furnishing textiles
                madeups.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
